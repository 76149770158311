import React, {useCallback, useEffect, useState} from 'react';
import b_ from 'b_';
import { Input, message, Select, Table, Tag, Tooltip } from 'antd';
import EditableField from '../../../Components/EditableField';
import PrintAccountState from '../../../Components/Prints/PrintAccountStatus';
import PrintPrice from '../../../Components/Prints/PrintPrice';
import TextArea from 'antd/lib/input/TextArea';
// import ChangePassword from '../../Accounts/ChangePassword';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import ChangeToken from '../../Accounts/ChangeToken';
import './styles.scss';
import Expanded from './Expanded';
import EnabledJobs from '../../../Components/EnabledJobs';
import {fetchAccountsGroups, updateAccount, updateAccounts} from "../../../Reducers/accounts";
// import AccountsChosen from "../../Accounts/AccountsChosen";
import CurrencySelector from '../../../Components/CurrencySelector';
import PrintAccountsStatus from '../../../Components/Prints/PrintAccountStatus';
import PrintDate from '../../../Components/Prints/PrintDate';
import ImageViews from '../../../Components/ImageViews';
import LinksViews from '../../../Components/LinksViews';
import PhaseSelector from '../../../Components/PhaseSelector';
import { getStringValue } from '../../../Utils/subscriptionMask';
import ExpandViews from '../../../Components/ExpandViews';
import ExpandModal from '../../../Components/ExpandModal';
import PrintDateSubscr from '../../../Components/Prints/PrintDateSubscr';
import ViewSavedDataModal from '../../../Components/ImageViews';
import { EyeOutlined, GlobalOutlined } from '@ant-design/icons';
import CarouselView from '../../../Components/ViewsChildren/CarouselView';
import ListView from '../../../Components/ViewsChildren/List';
import ActionButtons from '../../Accounts/ActionButtons';

const b = b_.lock('AccountTable');

function AccountTable({ data, listData, isLoading, afterUpdate, className, updateAccountStatus }) {
  const dispatch = useDispatch();
  const fieldsChange = [
      "login",
      "phase",
      "authToken",
      "currency",
      "comment",
      "balLimit",
      "driver",
      "enabledJobs",
  ]

  // const {payload: clients} = useSelector(state => state.clients.list);
  const groups = useSelector(state => (state.accounts.accountsGroups.payload));

  useEffect(() => {
      !groups && dispatch(fetchAccountsGroups());
  }, [])

  // const loadings = useSelector(state => ({
  //   status: state.account.changeStatus.isLoading,
  //   updateAccounts: state.accounts.updateAccounts.isLoading,
  // }), shallowEqual);

  const updateAccountState = useCallback((data) => {
    dispatch(updateAccount(data)).then(() => {
        message.success('Account has been updated!');
        setTimeout(fetch, 1000);
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update Account.');
    });
  }, [dispatch, fetch]);


  // const updateAccountState = useCallback((data) => {
  //     dispatch(updateAccount(data)).then(() => {
  //         message.success('account has been updated!');
  //         setTimeout(afterUpdate(), 1000); 
  //     }).catch(error => {
  //         console.log(error);
  //         message.error('Can\'t update Enabled Jobs.');
  //     });
  // }, [dispatch, afterUpdate]);

    const updateAccountGroups = useCallback((data) => {
        console.log(data);
    }, [dispatch, afterUpdate]);

  const GetColumnsTable = () => {
      return [{
          dataIndex: "id",
          title: "ID",
          width: 70,
          align: "center",
      },
      {
          dataIndex: "login",
          title: "Login",
          width: 250,
          align: "center",
          render:(login, rec) => (
              <EditableField handleSave={updateAccountState}
                             title="Update Login"
                             field={"login"}
                             rec={rec}
                             withTitle
                             titlePopover="Edit Login"
                             isLoading={isLoading}
                             iconClassName={b('edit')}
                             initialValue={login}
                             changeBlock={({onChange, ...props}) => (
                                 <Input {...props}
                                        size="medium"
                                        onChange={e => onChange(e.target.value)}/>
                             )}>
                  {login}
              </EditableField>
          ),
      },
      {
          dataIndex: "status",
          title: "State",
          align: "center",
          width: 90,
          render:(status, rec) => (
            <EditableField
                    handleSave={updateAccountState}
                    field={"status"}
                             rec={rec}
                             title="Update Status"
                             titlePopover="Edit Status"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={isLoading}
                             initialValue={status}
                                   changeBlock={(props) => (
                                     <Select {...props} size="small">
                                       <Select.Option value="Disable">Disable</Select.Option>
                                       <Select.Option value="Enable">Enable</Select.Option>
                                     </Select>
                                   )}>
                        <PrintAccountsStatus
                                      fieldsChange={fieldsChange}
                                      handleSave={updateAccountStatus}
                                      status={status}
                                      field={"name"}
                                      rec={rec}/>
          </EditableField>)
                              },
                              {
                                title: 'Phase',
                                dataIndex: 'phase',
                                key: 'phase',
                                align: "center",
                                width: 100,
                                render:(value, rec) =>
                                      <EditableField handleSave={updateAccountState}
                                                     field={"phase"}
                                                     rec={rec}
                                                     title="Update Phase"
                                                     titlePopover="Edit phase"
                                                     iconClassName={b('edit')}
                                                     withTitle
                                                     isLoading={isLoading}
                                                     initialValue={value}
                                                     changeBlock={(props) => (
                                                         <PhaseSelector
                                                         {...props}
                                                         data={[
                                                                  {
                                                                  value: "Create", 
                                                                  label: "Create"
                                                                  }, 
                                                                  {
                                                                    value: "Prepare", 
                                                                    label: "Prepare"
                                                                    },
                                                                    {
                                                                      value: "Redeem", 
                                                                      label: "Redeem"
                                                                      },
                                                                      {
                                                                        value: "Archive", 
                                                                        label: "Archive"
                                                                        },
                                                                        {
                                                                          value: "PrepareArchive", 
                                                                          label: "PrepareArchive"
                                                                          },
                                                                          {
                                                                            value: "Purchase", 
                                                                            label: "Purchase"
                                                                            }
                                                                          ]}
                                                                           size="small"
                                                                           className="w100"
                                                         />
                                                     )}>
                                          {value}
                                      </EditableField>
                              },
      {
          dataIndex:"enabledJobs",
          title:"Enabled Jobs",
          align: "center",
          width: 150,
          render:(enabledJobs, rec) => (
              <EnabledJobs
                  rec={rec}
                  enabledJobs={enabledJobs}
                  field={"enabledJobs"}
                  iconClassName={b('edit')}
                  handleSave={updateAccountState}
              />),
      },
      {
          dataIndex:"currency",
          title:"Currency",
          align: "center",
          defaultView: true,
          width: 50,
          render:(value, rec) =>
              <EditableField handleSave={updateAccountState}
                             field={"currency"}
                             rec={rec}
                             title="Update Currency"
                             titlePopover="Edit Currency"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={isLoading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <CurrencySelector {...props}
                                                   size="small"
                                                   className="w100"
                                 />
                             )}>
                  {value}
              </EditableField>
      },
      {
          dataIndex : "balance",
          title : "Balance (EUR)",
          align : "center",
          width: 65,
          render : value => <PrintPrice value={value}/>,
      },
      {
          dataIndex:"dayLimitEur",
          title:"Limit (EUR)",
          align:"center",
          width: 65,
          render:(value, rec) => (<EditableField
              handleSave={updateAccountState}
              field={"dayLimitEur"}
              rec={rec}
              title="Update BalLimit"
              titlePopover="Edit BalLimit"
              iconClassName={b('edit')}
              withTitle
              isLoading={isLoading}
              initialValue={value}
              changeBlock={({onChange, ...props}) => (
                  <Input {...props}
                         size="small"
                         onChange={e => onChange(e.target.value)}/>
              )}>
              <PrintPrice value={value}/>
          </EditableField>),
      },
      {
        title: 'Subscr type',
        dataIndex: 'details.subscrType',
        key: 'details.subscrType',
        width: 65,
        render:(value, rec) =>
              <EditableField handleSave={updateAccountState}
                             field={"subscrType"}
                             rec={rec}
                             title="Update Subscription"
                             titlePopover="Edit subscription"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={isLoading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <PhaseSelector
                                 data={[{value: 0, label: "No"}, {value: 1, label: "Pass"}, {value: 2, label: "EA"}, {value: 3, label: "Pass/EA"}]}
                                 {...props}
                                size="medium"
                                className="w100"
                                 />
                             )}>
                  {getStringValue(rec.details.subscrType)}
              </EditableField>
      },
      {
        title: 'Subscriptions',
        dataIndex: 'passUltNextCharge',
        key: 'subscriptions',
        align: 'center',
        width: 110,
        render: (_, rec) => {
        const now = Date.now()
        const passUltNextCharge = rec?.details?.passUltNextCharge
        return {
          props: {
            style: { background: now > passUltNextCharge ? "#fcf8e3" : "", color: now > passUltNextCharge ?  "#c09853" : "unset"}
          },
          children:
              <>
              {rec?.details?.passUltNextCharge &&
              <>
                Ultimate: <PrintDateSubscr subscr date={rec?.details?.passUltNextCharge} />
              </>
              }
              {rec?.details?.eaAccessNextCharge &&
              <>
                <br />
                EA: <PrintDateSubscr subscr date={rec?.details?.eaAccessNextCharge} />
              </>
              }
              </>
        }
        }
      },
      {
        title: 'Game limit',
        dataIndex: 'gameLimit',
        key: 'gameLimit',
        align: 'center',
        width: 80,
        render: (value, rec) => {
          return (
            <div className={b('shots')}>
              <Tooltip title="Subscription"><Tag>{value}/{listData?.subscrAllGameMax}</Tag></Tooltip>
              <br/>
              <Tooltip title="All" placement='bottom'><Tag>{rec?.allGamesCount}/{rec?.allGamesMax}</Tag></Tooltip>
            </div>
          )
        
        }
      },
      {
          dataIndex : "vm",
          title : "VM",
          align : "center",
          width: 40,
          render : (value, rec) => (<EditableField
          handleSave={updateAccountState}
          title="Update VM"
          rec={rec}
          field={"vm"}
          titlePopover="Edit VM"
          isLoading={isLoading}
          iconClassName={b('edit')}
          withTitle
          initialValue={value}
          changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     size="small"
                     type="number"
                     onChange={e => onChange(e.target.value)}/>
          )}>
          {value}
      </EditableField>),
      },
      {
        title: 'Proxy',
        dataIndex: 'proxy',
        key: 'proxy',
        align: 'center',
        width: 60,

      },
      {
        title: 'State changed at',
        dataIndex: 'statusTime',
        key: 'statusTime',
        align: 'center',
        width: 120,
        render: (statusTime) => <PrintDate withTime date={statusTime} />,
      },
      {
          dataIndex: "message",
          title: "Message",
          width: 400,
          render: (value, rec)  => (
          <span>
              {rec?.comment && (rec?.comment.includes("заблокирован на покупку") || rec?.comment.includes("Бан"))  ? rec.comment : value}
          </span>),
      },
      {
        title: 'Details',
        dataIndex: 'screenshots',
        key: 'screenshots',
        width: 100,
        align: "center",
        render: (screenshots, rec) => {
          let internals = {
                vmGroupName: rec.vmGroupName,
                statusTime: rec.statusTime,
                details: rec.details,
                newOrderHistory: rec.newOrderHistory,
                lastOrderHistory: rec.lastOrderHistory
              }
          return (
          <div className={b('shots')} >
            <ViewSavedDataModal
                  disabled={screenshots?.length && !screenshots?.length}
                  data={screenshots}
                  dimensions={{ width: "90vw", height: "90vh" }}
                  buttonIcon={<EyeOutlined />}
                  children={
                    <CarouselView data={screenshots} />
                  }
                  />
                  <ViewSavedDataModal
                   disabled={rec?.pageSources?.length && !rec?.pageSources.length}
                   data={rec.pageSources} 
                   dimensions={{ width: "50vw", height: "50vh" }}
                   buttonIcon={<GlobalOutlined />}
                   children={
                    <ListView data={rec.pageSources} />
                   }
                   />
            <ViewSavedDataModal
                   disabled={false}
                   data={internals} 
                   dimensions={{ width: "90vw", height: "90vh" }}
                   buttonIcon={"Internals"}
                   children={
                    
                      <ExpandViews title="Internals" content={internals} />
                    
                   }
                   />
                   <ViewSavedDataModal
                   disabled={false}
                   data={rec?.comment} 
                   dimensions={{ width: "90vw", height: "90vh" }}
                   buttonIcon={"Comments"}
                   children={
                    
                    <EditableField 
                      handleSave={updateAccountState}
                      rec={rec}
                      field={"comment"}
                      title="Update Comment"
                      titlePopover="Edit Comment"
                      isLoading={isLoading}
                      iconClassName={b('edit')}
                      withTitle
                      initialValue={rec?.comment}
                      changeBlock={({onChange, ...props}) => (
                        <TextArea {...props}
                                  size="medium"
                                  onChange={e => onChange(e.target.value)}/>)}>
                      {rec?.comment}
                    </EditableField>
                    
                   } />
          </div>
          )
        }

      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        fixed: "right",
        render: ( _, record) => <ActionButtons
        handleSave={updateAccountState}
        record={record}
        afterUpdate={afterUpdate}
        // value={item?.isActive?.toString() || ''}
      />
      },
    ]

  }
  const [columns, setColumns] = useState(GetColumnsTable())
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || isLoading

  useEffect( () => {
      setColumns([...GetColumnsTable()])
  },[data])

  return <Table bordered
                className={`${b()} ${className}`}
                loading={isLoading}
                size="small"
                scroll={{ x: 'min-content' }}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowClassName={() => b('lock')}
                rowKey="id"
                expandable={{
                  expandedRowRender: record => <Expanded record={record} />,
                }}
  >

  </Table>;
}

export default AccountTable;
