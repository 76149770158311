import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Col, Button, DatePicker, Input, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import CurrencySelector from '../CurrencySelector';

const { Option } = Select;

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function AlgoStatFilters({ className, filters, onFilter, withCodeFilter = true, afterUpdate }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  const handleResetFilters = useCallback(() => {
    setCurFilters([])
    onFilter()
  }, [setCurFilters])

  console.log('curFilters', curFilters)
  // console.log('filters', filters)
  return (
    <>
  <Row justify={"space-between"}> 

  <Col>
      <span className={b('label')}>Sold Today</span>

      <Select
      value={curFilters.soldToday ? curFilters.soldToday[0] : null}
      style={{width: '176px'}}
      onChange={(value) => handleChangeFilter(value, 'soldToday')}
    >
      <Option value={true}>Yes</Option>
      <Option value={null}>All</Option>
      <Option value={false}>No</Option>
    </Select>
    </Col>

    <Col>
      <span className={b('label')}>Sold Yesterday</span>

      <Select
      value={curFilters.soldYesterday ? curFilters.soldYesterday[0] : null}
      style={{width: '176px'}}
      onChange={(value) => handleChangeFilter(value, 'soldYesterday')}
    >
      <Option value={true}>Yes</Option>
      <Option value={null}>All</Option>
      <Option value={false}>No</Option>
    </Select>
    </Col>

    <Col>
      <span className={b('label')}>On Sale</span>

      <Select
      value={curFilters.onSale ? curFilters.onSale[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'onSale')}
      // defaultValue='Purchase'
      // placeholder='Purchase'
    >
      <Option value={true}>Yes</Option>
      <Option value={null}>All</Option>
      <Option value={false}>No</Option>
    </Select>
    </Col>
<Col>
    <span className={b('label')}>Currency</span>
    <Select
      value={curFilters.currency ? curFilters.currency[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'currency')}
    >
      <Option value="USD">USD</Option>
      <Option value="EUR">EUR</Option>
      <Option value="BRL">BRL</Option>
      <Option value="COP">COP</Option>
    </Select>
    </Col>

    <Col>
    <span className={b('label')}>Subsсr</span>
    <Select
      value={curFilters.subscr ? curFilters.subscr[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'subscr')}
    >
      <Option value="0">No</Option>
      <Option value="2">EA</Option>
      <Option value="1">Pass</Option>
      <Option value="3">EA, Pass</Option>
    </Select>
    </Col>
  
</Row>
<Row gutter={8} style={{marginBottom: '16px'}}>
<Col>

<span className={b('label')} />
    <Button
      type="primary"
      onClick={handleResetFilters}
      className={b('item')}
      icon={<RedoOutlined />}
    >
      Reset Filters
    </Button>
    </Col>
    <Col >
    <span className={b('label')} />
    <Button
      type="primary"
      onClick={() => onFilter(curFilters)}
      className={b('item')}
      icon={<FilterOutlined />}
    >
      Filter
    </Button>
    
    </Col>
    </Row>
</>
  )
}

export default AlgoStatFilters;
