import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Col, Button, DatePicker, Input, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import CurrencySelector from '../CurrencySelector';

const { Option } = Select;
const { Search } = Input;

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function AccountsFilters({ className, filters, onFilter, withCodeFilter = true, afterUpdate }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters((prevFilters) => ({ ...prevFilters, [field]: preparedValue }));
  }, [curFilters, setCurFilters]);

  const handleResetFilters = useCallback(() => {
    for (var prop in filters) delete filters[prop];
    setCurFilters({ phase: ['Purchase']});
    onFilter()
  }, [filters, onFilter])

  console.log('curFilters', curFilters)
  // console.log('filters', filters)
  return (
    <>
  <Row justify={"space-between"}> 

  <Col>
      <span className={b('label')}>Phase</span>

      <Select
      value={curFilters.phase ? curFilters.phase[0] : 'Purchase'}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'phase')}
      // defaultValue='Purchase'
      // placeholder='Purchase'
    >
      <Option value="Purchase">Purchase</Option>
      <Option value="Create">Create</Option>
      <Option value="Prepare">Prepare</Option>
      <Option value="Redeem">Redeem</Option>
      <Option value="PrepareArchive">PrepareArchive</Option>
    </Select>
    </Col>
<Col>
    <span className={b('label')}>Status</span>
    <Select
      value={curFilters.status ? curFilters.status[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'status')}
    >
      <Option value="Enabled">Enabled</Option>
      <Option value="Disabled">Disabled</Option>
      <Option value="Invalid">Invalid</Option>
      <Option value="Invalid60">Invalid60</Option>
      {curFilters?.phase?.includes("Create") &&
      <>
      <Option value="FailedToCreate">FailedToCreate</Option>
      <Option value="ToCreate">ToCreate</Option>
      </>
      }
    </Select>
    </Col>

    <Col>
    <span className={b('label')}>All Games Limited</span>
    <Select
      value={curFilters.allGamesLimited ? curFilters.allGamesLimited[0] : null}
      style={{width: '176px'}}
      onChange={(value) => handleChangeFilter(value, 'allGamesLimited')}
    >
      <Option value={true}>Yes</Option>
      <Option value={false}>No</Option>
    </Select>
    </Col>

    <Col>
    <span className={b('label')}>Group</span>
   <CurrencySelector value={curFilters.groups ? curFilters.groups[0] : null} onChange={(value) => handleChangeFilter(value, 'groups')} wd={'200px'}/>
    </Col>

    <Col>
    <span className={b('label')}>VM</span>
      <Input 
        value={curFilters.vm ? curFilters.vm[0] : null}
        placeholder="search vm"
        onChange={(e) => handleChangeFilter(e.target.value, 'vm')}
      /> 
    </Col>

    <Col>
    <span className={b('label')}>Subsсr</span>
    <Select
      value={curFilters.subscr ? curFilters.subscr[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'subscr')}
    >
      <Option value="0">No</Option>
      <Option value="2">EA</Option>
      <Option value="1">Pass</Option>
      <Option value="3">EA, Pass</Option>
    </Select>
    </Col>

    <Col>
    <span className={b('label')}>Subscribed</span>
    <Select
      value={curFilters.subscrOff ? curFilters.subscrOff[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'subscrOff')}
    >
      <Option value={false}>Now subscribed</Option>
      <Option value={true}>Was subscribed</Option>
    </Select>
    </Col>

    <Col>
    <span className={b('label')}>Proxies</span>
    <Select
      value={curFilters.proxy ? curFilters.proxy[0] : null}
      style={{width: '176px'}}
      onChange={(value) => handleChangeFilter(value, 'proxy')}
    >
      <Option value={"OVH"}>OVH</Option>
      <Option value={"Other"}>Other</Option>
    </Select>
    </Col>
  
</Row>
<Row gutter={8} style={{marginBottom: '16px'}}>
<Col>

<span className={b('label')} />
    <Button
      type="primary"
      onClick={handleResetFilters}
      className={b('item')}
      icon={<RedoOutlined />}
    >
      Reset Filters
    </Button>
    </Col>
    <Col >
    <span className={b('label')} />
    <Button
      type="primary"
      onClick={() => onFilter(curFilters)}
      className={b('item')}
      icon={<FilterOutlined />}
    >
      Filter
    </Button>
    
    </Col>
    </Row>
</>
  )
}

export default AccountsFilters;
