import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';
import AccountsService from '../Services/AccountsService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const accountsListFetch = createAction('accounts/list', (params) => ({
  payload: AccountsService.getAccountsList(params),
}))

export const accountsListToCreateFetch = createAction('accounts/to-create-list', (params) => ({
  payload: AccountsService.getAccountsToCreateList(params),
}))

export const balancesListFetch = createAction('accounts/balances', () => ({
  payload: AccountsService.fetchBalancesList(),
}))

export const createAccount = createAction('account/create', (data) => ({
  payload: AccountsService.createAccount(data),
}))

export const createAccounts = createAction('accounts/create', (data) => ({
  payload: AccountsService.createAccounts(data),
}))

export const fetchAuxAccount = createAction('accounts/fetch-aux', () => ({
  payload: AccountsService.fetchAux(),
}))

export const fetchAccountsGroups = createAction('accounts/groups', () => ({
  payload: AccountsService.fetchAccountsGroups(),
}));

export const updateAccounts = createAction('accounts/updates', (data) => ({
  payload: AccountsService.updateAccountsOld(data),
}));

export const updateAccount = createAction('accounts/update', (data) => ({
  payload: AccountsService.updateAccount(data),
}));

export const groupChangeAccounts = createAction('accounts/group-edit', (data) => ({
  payload: AccountsService.groupEditAccounts(data),
}));

export const downloadAccounts = createAction('accounts/download', () => ({
  payload: AccountsService.downloadAccountsList(),
}));

export const getCurrenciesList = createAction('accounts/currencies', () => ({
  payload: AccountsService.getCurrencies(),
}));

export const getAccountsPoolInfo = createAction('accounts/pool-info', () => ({
  payload: AccountsService.getPoolInfo(),
}));

export const canBuyRequest = createAction('accounts/can-buy', (data) => ({
  payload: AccountsService.requestCanBuy(data),
}));

export const purchase = createAction('accounts/purchase', (data) => ({
  payload: AccountsService.purchase(data),
}));

export const removeUserDataDir = createAction('drivers/remove-chrome-profile', (accountId) => ({
  payload: AccountsService.removeUserDataDir(accountId),
}));

export const findAndSetProxy = createAction('accounts/set-proxy', (accountId) => ({
  payload: AccountsService.setAccountProxy(accountId)
}));

export const accessInfo = createAction('accounts/account-access-info', (accountId) => ({
  payload: AccountsService.accessAccountInfo(accountId),
}));

const initState = {
  list: getDefaultState(),
  toCreateList: getDefaultState(),
  balances: getDefaultState(),
  create: getDefaultState(),
  createAccounts: getDefaultState(),
  aux: getDefaultState(),
  accountsGroups: getDefaultState(),
  updates: getDefaultState(),
  groupEdit: getDefaultState(),
  update: getDefaultState(),
  download: getDefaultState(),
  currencies: getDefaultState(),
  poolInfo: getDefaultState(),
  canBuy: getDefaultState(),
  purchase: getDefaultState(),
  deleteDataUser: getDefaultState(),
  setProxy: getDefaultState(),
  accountAccessInfo: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(accountsListFetch, 'list'),
  ...getDefaultHandler(accountsListToCreateFetch, 'toCreateList'),
  ...getDefaultHandler(balancesListFetch, 'balances'),
  ...getDefaultHandler(createAccount, 'create'),
  ...getDefaultHandler(createAccounts, 'createAccounts'),
  ...getDefaultHandler(fetchAuxAccount, 'aux'),
  ...getDefaultHandler(fetchAccountsGroups, 'accountsGroups'),
  ...getDefaultHandler(updateAccounts, 'updates'),
  ...getDefaultHandler(groupChangeAccounts, 'groupEdit'),
  ...getDefaultHandler(updateAccount, 'update'),
  ...getDefaultHandler(downloadAccounts, 'download'),
  ...getDefaultHandler(getCurrenciesList, 'currencies'),
  ...getDefaultHandler(getAccountsPoolInfo, 'poolInfo'),
  ...getDefaultHandler(canBuyRequest, 'canBuy'),
  ...getDefaultHandler(purchase, 'purchase'),
  ...getDefaultHandler(removeUserDataDir, 'deleteDataUser'),
  ...getDefaultHandler(findAndSetProxy, 'setProxy'),
  ...getDefaultHandler(accessInfo, 'accountAccessInfo'),
});
