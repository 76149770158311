import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { Col, Button, DatePicker, Input, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';

const { Option } = Select;

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function PurchaseStatFilters({ className, filters, onFilter, withCodeFilter = true }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters({ ...curFilters, [field]: preparedValue });
  }, [curFilters, setCurFilters]);

  const handleResetFilters = useCallback(() => {
    setCurFilters([])
    onFilter()
  }, [curFilters, setCurFilters])

  return (
    <>
  <Row justify={"space-between"}>
    <Col >
    <span className={b('label')}>Sold today</span>

<Select
value={curFilters.sold_today ? curFilters.sold_today[0] : null}
style={{width: '176px'}}
// className="item"
onChange={(value) => handleChangeFilter(value, 'sold_today')}
>
<Option value="true">Yes</Option>
<Option value={null}>All</Option>
<Option value="false">No</Option>
</Select>
    </Col>
    <Col>
    <span className={b('label')}>Sold Yesterday</span>

        <Select
        value={curFilters.sold_yesterday ? curFilters.sold_yesterday[0] : null}
        style={{width: '176px'}}
        // className="item"
        onChange={(value) => handleChangeFilter(value, 'sold_yesterday')}
        >
        <Option value="true">Yes</Option>
<Option value={null}>All</Option>
<Option value="false">No</Option>
        </Select>
    </Col>
    
    <Col>
    <span className={b('label')}>On Sale</span>

        <Select
        value={curFilters.on_sale ? curFilters.on_sale[0] : null}
        style={{width: '176px'}}
        // className="item"
        onChange={(value) => handleChangeFilter(value, 'on_sale')}
        >
       <Option value="true">Yes</Option>
<Option value={null}>All</Option>
<Option value="false">No</Option>
        </Select>
    </Col>
  {/* </Row> */}
  {/* <Row justify={'space-between'} className={`${className} ${b()}`}> */}

  <Col>
      <span className={b('label')}>Subscription</span>

      <Select
      value={curFilters.subscription ? curFilters.subscription[0] : null}
      style={{width: '176px'}}
      // className="item"
      onChange={(value) => handleChangeFilter(value, 'subscription')}
    >
      <Option value={null}>All</Option>
      <Option value="0">No</Option>
      <Option value="1">Pass</Option>
      <Option value="2">EA</Option>
      <Option value="3">Pass, EA</Option>
    </Select>
    </Col>

  <Col >
  <span className={b('label')}>Currency</span>

<Select
value={curFilters.currency ? curFilters.currency[0] : null}
style={{width: '176px'}}
// className="item"
onChange={(value) => handleChangeFilter(value, 'currency')}
>
<Option value="USD">USD</Option>
<Option value="EUR">EUR</Option>
<Option value="BRL">BRL</Option>
<Option value="COP">COP</Option>
</Select>
    </Col>

    <Col >
    <span className={b('label')}>Multi</span>

<Select
value={curFilters.multi ? curFilters.multi[0] : null}
style={{width: '176px'}}
// className="item"
onChange={(value) => handleChangeFilter(value, 'multi')}
>
<Option value="true">Yes</Option>
<Option value={null}>All</Option>
<Option value="false">No</Option>
</Select>
    </Col>
  
</Row>
<Row gutter={8} style={{marginBottom: '16px'}}>
<Col>

<span className={b('label')} />
    <Button
      type="primary"
      onClick={handleResetFilters}
      className={b('item')}
      icon={<RedoOutlined />}
    >
      Reset Filters
    </Button>
    </Col>
    <Col >
    <span className={b('label')} />
    <Button
      type="primary"
      onClick={() => onFilter(curFilters)}
      className={b('item')}
      icon={<FilterOutlined />}
    >
      Filter
    </Button>
    
    </Col>
    </Row>
</>
  )
}

export default PurchaseStatFilters;
