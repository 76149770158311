export const getStringValue = (selectedValue) => {
  switch (selectedValue) {
    case 0:
      return 'No';
    case 1:
      return 'Pass';
    case 2:
      return 'EA';
    case 3:
      return 'Pass, EA';
    default:
      return '';
  }
};
